import React from 'react';
import PropTypes from 'prop-types';
import { Img } from '../UI';
import BackgroundImage from '../UI/BackgroundImage/BackgroundImage';
import { getCdnImage } from '../../adapters/cloudinary.adapter';

const BlockWrapperWithImage = (props) => {
    const { topImage, leftImage, rightImage, logos } = props;

    const backgroundImage = topImage && `url(${getCdnImage(topImage.cfmedia.url)})`;
    const leftImagePath = getCdnImage(leftImage && leftImage.cfmedia.url);
    const rightImagePath = getCdnImage(rightImage && rightImage.cfmedia.url);

    const background = {
        backgroundImage: `url(${leftImagePath}), url(${rightImagePath})`,
    };

    const backgroundTopImage = {
        backgroundImage,
    };

    const renderLogos = () => {
        if (!logos) {
            return null;
        }

        return (
            <div className="top-logos">
                {
                    logos.items.map((logo, i) => {
                        return <Img src={logo.cfmedia.url} alt={logo.cfmedia.description} key={i} />;
                    })
                }
            </div>
        );
    };

    const renderTopImage = () => <BackgroundImage className="wrapper-top-image" backgroundImage={backgroundTopImage} ariaLabel={topImage?.cfmedia?.description}>{renderLogos()}</BackgroundImage>;

    return (
        <div className="block-wrapper-with-image" style={{ backgroundImage: `${background.backgroundImage}` }}>
            {topImage && renderTopImage()}
            {props.children}
        </div>
    );
};

BlockWrapperWithImage.propTypes = {
    children: PropTypes.element,
    topImage: PropTypes.object,
    leftImage: PropTypes.object,
    rightImage: PropTypes.object,
    logos: PropTypes.object,
};

export default BlockWrapperWithImage;
